import { Breadcrumb, Layout, Menu, theme, Row, Col, Drawer } from "antd";
import { useState, useEffect } from "react";
import { Outlet, RouterProvider, useLocation, useNavigate } from "react-router-dom";
import logoFooterSVG from "../../assets/images/logo-footer.svg";
import "./DrawerMenu.css";
import { Link, animateScroll as scroll } from "react-scroll";
import { Link as LinkRouter } from "react-router-dom";

const { Header, Content, Footer } = Layout;

const items = [
  {
    name: "PRÉMIOS",
    key: "premios",
    to: "premios",
    scrollTo: true,
  },
  {
    name: "PRAZOS",
    key: "prazos",
    to: "prazos",
    scrollTo: true,
  },
  {
    name: "COMITÉ CIENTÍFICO",
    key: "committee",
    to: "committee",
    scrollTo: true,
  },
  {
    name: "REGULAMENTO",
    key: "regulation",
    to: "regulation",
    scrollTo: true,
  },
  {
    name: "PROGRAMA DO CURSO",
    key: "programa",
    to: "programa",
    scrollTo: false,
  },
];

const FooterMobile = () => {
  return (
    <Footer className="footer_mobile">
      <div className="first_row">
        <img src={logoFooterSVG} />
      </div>
    </Footer>
  );
};

const DrawerMenu = ({ open, close }) => {
  const [current, setCurrent] = useState("");
  const [isLoading, setIsLoading] = useState(true);

  const navigate = useNavigate();
  const location = useLocation();

  window.onbeforeunload = function () {
    setIsLoading(true);
    window.scrollTo(0, 0);
  };

  function onClick(e) {
    if (e.key) {
      console.log("click ", e);
      close();
      navigate(`/${e.key}`);
      setCurrent(e.key);
    } else {
      close();
    }
  }

  return (
    <Drawer open={open} closable onClose={close} className="mobile_drawer" footer={<FooterMobile />}>
      <Menu mode="vertical" defaultSelectedKeys={[""]} onClick={onClick} onChange={onClick} selectedKeys={[current]}>
        {location.pathname === "/submeter" ? (
          <Menu.Item key={""}>
            <LinkRouter href="/">« Voltar</LinkRouter>
          </Menu.Item>
        ) : (
          <>
            {items.map((item) => {
              return (
                <Menu.Item key={item.key}>
                  {item.scrollTo ? (
                    <Link onClick={onClick} activeClass="active" to={item.to} spy={true} smooth={true} offset={-70} duration={500}>
                      {item.name}
                    </Link>
                  ) : (
                    <LinkRouter to={item.to}>{item.name}</LinkRouter>
                  )}
                </Menu.Item>
              );
            })}
            <Menu.Item key={"submeter"} className="inscricao_menu_button">
              <LinkRouter to={"/submeter"}>PARTICIPAR</LinkRouter>
            </Menu.Item>
          </>
        )}
      </Menu>
    </Drawer>
  );
};
export default DrawerMenu;
