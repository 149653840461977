import { Breadcrumb, Layout, Menu, theme, Row, Col, Drawer, Modal, Button, Form, Input, notification, Radio } from 'antd';
import { useState, useEffect } from 'react';
import {
    Outlet,
    RouterProvider,
    useLocation,
    useNavigate,
} from "react-router-dom";
import axios from 'axios';
import './ModalAddUser.css';
import config from "../../utils/server";

const { Header, Content, Footer } = Layout;

const ModalAddUser = ({ open, close }) => {

    const [isLoadingButton, setIsLoadingButton] = useState(false);

    const [form] = Form.useForm();

    function handleAddUser(values) {
        setIsLoadingButton(true);

        axios.post(`${config.server_ip}/auth/register`, {
            data: values
        }).then((res) => {
            setTimeout(() => {
                if (!res.data.user_registered) {
                    notification.error({
                        message: 'Utilizador existente',
                        description: 'Já existe um utilizador com esse e-mail, verifica novamente os dados inseridos'
                    })
                } else {
                    notification.success({
                        message: 'Utilizador adicionado',
                        description: 'Utilizador foi adicionado com sucesso. Já pode aceder ao painel com os respetivos dados.'
                    })
                    close();
                    form.resetFields();
                }
                setIsLoadingButton(false);
            }, 1500);
        }).catch((err) => {
            setIsLoadingButton(false);
            console.log(err);
        })
    }

    function handleCancel() {
        console.log('cancel');
        close();
    }

    return (
        <Modal
            open={open}
            closable
            onOk={handleAddUser}
            onCancel={handleCancel}
            className='modal_add_user'
            title="Adicionar administrador"
            width={800}
            footer={[
                <Button key="back" className='cancel_button' onClick={handleCancel}>
                    Cancelar
                </Button>,
                <Button key="submit" type="primary" className='add_button' loading={isLoadingButton} onClick={form.submit}>
                    Adicionar
                </Button>
            ]}
        >
            <Row gutter={24}>
                <Col span={24}>
                    <Form
                        form={form}
                        id="form_add_user"
                        onFinish={handleAddUser}
                        className='form_add_user'
                    >
                        <Form.Item
                            labelCol={{ span: 24 }}
                            name='name'
                            label="Nome"
                            rules={[
                                {
                                    required: true,
                                    message: 'NOME'
                                }
                            ]}
                        >
                            <Input size='large' placeholder="Nome" />
                        </Form.Item>
                        <Form.Item
                            labelCol={{ span: 24 }}
                            name='email'
                            label="E-mail"
                            rules={[
                                {
                                    required: true,
                                    message: 'EMAIL'
                                }
                            ]}
                        >
                            <Input size='large' placeholder="E-mail" />
                        </Form.Item>
                        <Form.Item
                            labelCol={{ span: 24 }}
                            name="password"
                            label="Password"
                            rules={[{ required: true, message: 'Este campo é obrigatório' }]}
                            hasFeedback
                        >
                            <Input.Password size="large" placeholder="Password" />
                        </Form.Item>
                        <Form.Item
                            labelCol={{ span: 24 }}
                            name="type"
                            label="Tipo"
                            rules={[{ required: true, message: 'Este campo é obrigatório' }]}
                            hasFeedback
                        >
                            <Radio.Group>
                                <Radio value={1}>Administrador</Radio>
                                <Radio value={2}>Gestor</Radio>
                            </Radio.Group>
                        </Form.Item>
                    </Form>
                </Col>
            </Row>
        </Modal>
    )
};
export default ModalAddUser;