import { useState, useEffect } from 'react';
import { Breadcrumb, Layout, Menu, theme, Row, Col, Form, Input, Upload, Button, Checkbox, notification, Table } from 'antd';
import Search from 'antd/es/input/Search';
import { DeleteOutlined, EditOutlined, PlusOutlined } from '@ant-design/icons';
import imageLogin from '../../assets/images/logo-hero.svg'
import axios from 'axios';
import './AdminUsers.css';
import ModalAddUser from '../../components/ModalAddUser/ModalAddUser';
import config from "../../utils/server";

const columns = [
    {
        title: 'Name',
        dataIndex: 'name',
        key: 'name',
    },
    {
        title: 'E-mail',
        dataIndex: 'email',
        key: 'email',
    },
    {
        title: 'Tipo',
        dataIndex: 'type_name',
        key: 'type_name',
    },
    {
        title: '',
        dataIndex: 'actions',
        key: 'actions',
        width: '10%'
    },
];

function AdminUsers() {

    const [isLoading, setIsLoading] = useState(true);
    const [isOpenModalAddUser, setIsOpenModalAddUser] = useState(false);
    const [user, setUser] = useState({});
    const [usersFullData, setUsersFullData] = useState([]);
    const [users, setUsers] = useState([]);
    const [filteredUsers, setFilteredUsers] = useState([]);

    useEffect(() => {
        let tokenData = localStorage.getItem('token');
        if (tokenData) {
            axios.post(`${config.server_ip}/auth/verifyToken`, {
                data: tokenData
            }).then((res) => {
                let userData = res.data.user_data;
                setUser(userData);
                handleGetData(userData);
            }).catch((err) => {
                console.log(err);
            })
        }
    }, [])


    function handleGetData(userData) {
        axios.get(`${config.server_ip}/info/getUsers`).then((res) => {
            let usersData = res.data;

            let aux = [];
            usersData.forEach(e => {
                let auxObject = {
                    name: e.name,
                    email: e.email,
                    type_name: e.type === 1 ? 'Administrador' : 'Gestor',
                    actions: <div>
                        <Button className='mr-10'>
                            <EditOutlined />
                        </Button>
                        {(e.email !== "@master" && userData.type === 1) &&
                            <Button>
                                <DeleteOutlined />
                            </Button>
                        }
                    </div>
                }
                aux.push(auxObject);
            });
            setUsersFullData(usersData);
            setUsers(aux);
            setFilteredUsers(aux);
            setTimeout(() => {
                setIsLoading(false);
            })
        }).catch((err) => {
            console.log(err);
            setIsLoading(false);
        })
    }


    function handleSearchData(e) {
        let string = e.target.value;
        console.log(string);
        let auxArray = users.filter((item) => (item.name.toLowerCase()).includes(string.toLowerCase()));
        console.log(auxArray);
        setFilteredUsers(auxArray);
    }

    function handleOpenAddUser() {
        setIsOpenModalAddUser(true);
    }

    function handleCloseModalAddUser() {
        handleGetData(user);
        setIsOpenModalAddUser(false);
    }

    return (
        <div className="admin_content">
            <Row className="content">
                <Col span={24}>
                    <Row gutter={[24]}>
                        <Col span={24}>
                            <Row className='dash_card'>
                                <Col span={24}>
                                    <div className='table_header'>
                                        <div className='title'>
                                            <h1>Administradores</h1>
                                        </div>
                                        <div className='search_div'>
                                            {user.type === 1 &&
                                                <Button
                                                    className="add_user_button"
                                                    onClick={handleOpenAddUser}
                                                >
                                                    Adicionar
                                                </Button>
                                            }
                                            <Search onInput={handleSearchData} size='large' placeholder='Procurar...'></Search>
                                        </div>
                                    </div>
                                    <Table dataSource={filteredUsers} columns={columns} loading={isLoading} />
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </Col>
            </Row>
            <ModalAddUser open={isOpenModalAddUser} close={handleCloseModalAddUser} />
        </div>
    );
}

export default AdminUsers;
