import { useState, useEffect } from 'react';
import { Breadcrumb, Layout, Menu, theme, Row, Col, Form, Input, Upload, Button, Checkbox, notification, Table, Select } from 'antd';
import { PlusOutlined, UserOutlined } from '@ant-design/icons';
import axios from 'axios';
import moment from 'moment';
import Search from 'antd/es/input/Search';
import { CSVLink, CSVDownload } from "react-csv";
import './AdminDashboard.css';
import imageLogin from '../../assets/images/logo-hero.svg'
import config from "../../utils/server";

const columns = [
    {
        title: 'Name',
        dataIndex: 'name',
        key: 'name',
        sorter: (a, b) => a.name.toString().localeCompare(b.name)
    },
    {
        title: 'E-mail',
        dataIndex: 'email',
        key: 'email',
        sorter: (a, b) => a.email.toString().localeCompare(b.email)
    },
    {
        title: 'Phone',
        dataIndex: 'phone',
        key: 'phone',
        sorter: (a, b) => a.phone.toString().localeCompare(b.phone)
    },
    {
        title: 'Ordem',
        dataIndex: 'order_name',
        key: 'order_name',
        sorter: (a, b) => a.order_name.toString().localeCompare(b.order_name)
    },
    {
        title: 'Nr. Ordem',
        dataIndex: 'order_nr',
        key: 'order_nr',
        sorter: (a, b) => a.order_nr.toString().localeCompare(b.order_nr)
    },
    {
        title: 'Ficheiro',
        dataIndex: 'file_name',
        key: 'file_name',
    },
    {
        title: 'Data de inscrição',
        dataIndex: 'created_at',
        key: 'created_at',
        sorter: (a, b) => moment(a.created_at_unix).unix() - moment(b.created_at_unix).unix()
    },
];

function AdminDashboard() {

    const [isLoading, setIsLoading] = useState(true);
    const [subscribers, setSubscribers] = useState([]);
    const [subscribersWeek, setSubscribersWeek] = useState([]);
    const [subscribersMonth, setSubscribersMonth] = useState([]);

    useEffect(() => {
        handleGetData();
    }, [])


    function handleGetData() {
        const today = moment();
        const month = moment().month();
        const from_date = today.startOf('week').format('YYYY-MM-DD HH:mm:ss')
        const to_date = today.endOf('week').format('YYYY-MM-DD HH:mm:ss')

        axios.get(`${config.server_ip}/info/getSubscribersDashboard`, {
            params: { weekDates: JSON.stringify([from_date, to_date]), month: (month + 1) }
        }).then((res) => {
            console.log(res);
            let subscribersData = res.data[0];
            let subscribersDataWeek = res.data[1];
            let subscribersDataMonth = res.data[2];
            setSubscribers(subscribersData);
            setSubscribersWeek(subscribersDataWeek);
            setSubscribersMonth(subscribersDataMonth);
            setIsLoading(false);
        }).catch((err) => {
            console.log(err);
            setIsLoading(false);
        })
    }

    return (
        <div className="admin_content">
            <Row className="content">
                <Col span={24}>
                    <Row gutter={[24]}>
                        <Col span={8}>
                            <div className='dash_card'>
                                <p className='dash_title'>Total de inscritos</p>
                                <div className='dash_card_content'>
                                    <div className='icon'>
                                        <UserOutlined />
                                    </div>
                                    <div className='number'>
                                        <p>{subscribers.length}</p>
                                    </div>
                                </div>
                            </div>
                        </Col>
                        <Col span={8}>
                            <div className='dash_card'>
                                <p className='dash_title'>Total de inscritos esta semana</p>
                                <div className='dash_card_content'>
                                    <div className='icon'>
                                        <UserOutlined />
                                    </div>
                                    <div className='number'>
                                        <p>{subscribersWeek.length}</p>
                                    </div>
                                </div>
                            </div>
                        </Col>
                        <Col span={8}>
                            <div className='dash_card'>
                                <p className='dash_title'>Total de inscritos este mês</p>
                                <div className='dash_card_content'>
                                    <div className='icon'>
                                        <UserOutlined />
                                    </div>
                                    <div className='number'>
                                        <p>{subscribersMonth.length}</p>
                                    </div>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </Col>
            </Row>
        </div>
    );
}

export default AdminDashboard;
