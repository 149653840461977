import React, { useEffect, useState } from "react";
import { Button, Col, Divider, Row } from "antd";
import "./Program.css";

function Program() {
  const [width, setWidth] = useState(window.innerWidth);

  useEffect(() => {
    window.addEventListener("resize", handleResize);
    handleResize();
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const handleResize = () => {
    setWidth(window.innerWidth);
  };

  return (
    <div className="regulation_page">
      <Row className="container" align="center">
        <h1 className="title_page">PROGRAMA</h1>
        <Col span={24}></Col>
      </Row>
    </div>
  );
}

export default Program;
