import { useEffect, useState } from "react";
import * as ReactDOM from "react-dom";
import { createBrowserRouter, RouterProvider, Router } from "react-router-dom";
import { ConfigProvider } from "antd";
import axios from "axios";
import ReactGA from "react-ga4";
import "./App.css";
import Home from "./pages/Home/Home";
import Subscribe from "./pages/Subscribe/Subscribe";
import Submit from "./pages/Submit/Submit";
import Login from "./pages/Login/Login";
import Main from "./components/Main/Main";
import MainAdmin from "./components/MainAdmin/MainAdmin";
import AdminDashboard from "./pages/AdminDashboard/AdminDashboard";
import AdminSubscribers from "./pages/AdminSubscribers/AdminSubscribers";
import AdminFinalists from "./pages/AdminFinalists/AdminFinalists";

import AdminUsers from "./pages/AdminUsers/AdminUsers";
import config from "./utils/server";
import Regulation from "./pages/Regulation/Regulation";
import PrivacyPolicy from "./pages/PrivacyPolicy/PrivacyPolicy";
import CookiesPolicy from "./pages/CookiesPolicy/CookiesPolicy";
import Program from "./pages/Program/Program";

const TRACKING_ID = "G-91799QYRG8"; // OUR_TRACKING_ID
ReactGA.initialize(TRACKING_ID);

function App() {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [user, setUser] = useState({});

  useEffect(() => {
    let tokenData = localStorage.getItem("token");
    console.log(tokenData);
    if (tokenData) {
      axios
        .post(`${config.server_ip}/auth/verifyToken`, {
          data: tokenData,
        })
        .then((res) => {
          console.log(res);
          setUser(res.data.user_data);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }, []);

  const router = createBrowserRouter([
    {
      path: "/",
      element: <Main />,
      children: [
        {
          path: "/",
          element: <Home />,
        },
        {
          path: "/programa",
          element: <Program />,
        },
        {
          path: "/submeter",
          element: <Submit />,
        },
        {
          path: "/regulamento",
          element: <Regulation />,
        },
        {
          path: "/politica-de-privacidade",
          element: <PrivacyPolicy />,
        },
        {
          path: "/politica-de-cookies",
          element: <CookiesPolicy />,
        },
      ],
    },
    {
      path: "/login",
      element: <Login handleLogin={handleLogin} />,
    },
    {
      path: "/admin/",
      element: <MainAdmin handleLogout={handleLogout} />,
      children: [
        {
          path: "/admin/dashboard",
          element: <AdminDashboard />,
        },
        {
          path: "/admin/subscribers",
          element: <AdminSubscribers />,
        },
        {
          path: "/admin/finalists",
          element: <AdminFinalists />,
        },
        {
          path: "/admin/users",
          element: <AdminUsers user={user} />,
        },
      ],
    },
  ]);

  function handleLogin(data) {
    setUser(data);
    setIsLoggedIn(true);
  }

  function handleLogout() {
    localStorage.removeItem("token");
    setIsLoggedIn(false);
  }

  return (
    <ConfigProvider
      theme={{
        token: {
          colorPrimary: "#F39325",
        },
      }}
    >
      <div className="App">
        <RouterProvider router={router} />
      </div>
    </ConfigProvider>
  );
}

export default App;
