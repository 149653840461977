import { useState, useEffect } from 'react';
import { Breadcrumb, Layout, Menu, theme, Row, Col, Form, Input, Upload, Button, Checkbox, notification, Table, Select } from 'antd';
import { LinkOutlined, PlusOutlined } from '@ant-design/icons';
import axios from 'axios';
import moment from 'moment';
import Search from 'antd/es/input/Search';
import { CSVLink, CSVDownload } from "react-csv";
import './AdminFinalists.css';
import imageLogin from '../../assets/images/logo-hero.svg'
import config from "../../utils/server";

const columns = [
    {
        title: 'Name',
        dataIndex: 'name',
        key: 'name',
        sorter: (a, b) => a.name.toString().localeCompare(b.name)
    },
    {
        title: 'E-mail',
        dataIndex: 'email',
        key: 'email',
        sorter: (a, b) => a.email.toString().localeCompare(b.email)
    },
    {
        title: 'Phone',
        dataIndex: 'phone',
        key: 'phone',
        sorter: (a, b) => a.phone.toString().localeCompare(b.phone)
    },
    {
        title: 'Ordem',
        dataIndex: 'order_name',
        key: 'order_name',
        sorter: (a, b) => a.order_name.toString().localeCompare(b.order_name)
    },
    {
        title: 'Local de trabalho',
        dataIndex: 'work_location',
        key: 'work_location',
        sorter: (a, b) => a.work_location.toString().localeCompare(b.work_location)
    },
    {
        title: 'Nr. Ordem',
        dataIndex: 'order_nr',
        key: 'order_nr',
        sorter: (a, b) => a.order_nr.toString().localeCompare(b.order_nr)
    },
    {
        title: 'Ficheiro',
        dataIndex: 'file_link',
        key: 'file_link',
    },
    {
        title: 'Data de inscrição',
        dataIndex: 'created_at',
        key: 'created_at',
        sorter: (a, b) => moment(a.created_at_unix).unix() - moment(b.created_at_unix).unix()
    },
];

function AdminFinalists() {

    const [isLoading, setIsLoading] = useState(true);
    const [subscribers, setSubscribers] = useState([]);
    const [filteredSubscribers, setFilteredSubscribers] = useState([]);
    const [currentSearchBy, setCurrentSearchBy] = useState('name');

    useEffect(() => {
        handleGetData();
    }, [])


    function handleGetData(v) {
        axios.get(`${config.server_ip}/info/getFinalists`).then((res) => {
            console.log(res);
            let subscribersData = res.data;
            if (subscribersData.length > 0) {
                for (let i = 0; i < subscribersData.length; i++) {
                    subscribersData[i].created_at_unix = moment(subscribersData[i].created_at).unix();
                    subscribersData[i].created_at = moment(subscribersData[i].created_at).format('DD-MM-YYYY HH:mm');
                    subscribersData[i].file_link = <div><Button onClick={() => window.open(`https://followreference.info/zambon/server/files/${subscribersData[i].file_name}`)}><LinkOutlined /></Button></div>;
                }
                setSubscribers(subscribersData);
                setFilteredSubscribers(subscribersData);
            }
            setIsLoading(false);
        }).catch((err) => {
            console.log(err);
            setIsLoading(false);
        })
    }


    function handleSearchData(e) {
        let string = e.target.value;
        console.log(string);
        let auxArray = subscribers.filter((item) => (item[currentSearchBy].toLowerCase()).includes(string.toLowerCase()));
        console.log(auxArray);
        setFilteredSubscribers(auxArray);
    }

    function handleChangeSearchBy(e) {
        setCurrentSearchBy(e);
    }

    function handleDownloadCSV(e) {
        console.log(e);
    }

    return (
        <div className="admin_content">
            <Row className="content">
                <Col span={24}>
                    <Row gutter={[24]}>
                        <Col span={24}>
                            <Row className='dash_card'>
                                <Col span={24}>
                                    <div className='table_header'>
                                        <div className='title'>
                                            <h1>Inscritos</h1>
                                        </div>
                                        <div className='search_div'>
                                            <CSVLink
                                                filename={`Inscritos_${moment().format('YYYYMMDD_HHmmss')}.csv`}
                                                data={filteredSubscribers}
                                                className="export_button"
                                                onClick={handleDownloadCSV}
                                            >
                                                Exportar
                                            </CSVLink>
                                            <Select
                                                style={{ width: 300 }}
                                                size='large'
                                                className='select_search_by'
                                                value={currentSearchBy}
                                                onChange={handleChangeSearchBy}
                                                options={[
                                                    { value: 'name', label: 'Nome' },
                                                    { value: 'order_nr', label: 'Número de Ordem' },
                                                    { value: 'phone', label: 'Telefone' }
                                                ]}
                                            />
                                            <Search onInput={handleSearchData} size='large' placeholder='Procurar...'></Search>
                                        </div>
                                    </div>
                                    <Table dataSource={filteredSubscribers} columns={columns} loading={isLoading} />
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </Col>
            </Row>
        </div>
    );
}

export default AdminFinalists;
